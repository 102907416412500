import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';

import Filters from './presentation.filters';
import StatusChart from './presentation.status';
import MostUsedChargeboxes from './presentation.mostused';
import MostActiveUsers from './presentation.mostactive';
import EnergyChart from './presentation.energy';
import RevenueChart from './presentation.revenue';
import ChargesChart from './presentation.charges';
import PaymentMethodsChart from './presentation.paymentmethods';
import Total from './presentation.total';
import ApiCalls from './presentation.apicalls';
import { Translate } from 'react-localize-redux';

const useStyles = makeStyles({
  container: {
    width: '100%',
    WebkitColumnGap: '1rem',
    MozColumnGap: '1rem',
    columnGap: '1rem',
    WebkitColumnFill: 'balance',
    MozColumnFill: 'balance',
    columnFill: 'balance',
    WebkitColumnWidth: '20rem',
    MozColumnWidth: '20rem',
    columnWidth: '20rem',
  },
  card: {
    margin: '0.5rem 0',
    display: 'inline-block',
    width: '100%',
    // width: '100%',
    // '&:first-child': {
    // 	margin: '0 1rem',
    // },
  },
});

const HomeCard = ({ title, subtitle, className, children }) => (
  <Card className={className}>
    {(title || subtitle) && <CardHeader title={title} subheader={subtitle} />}
    <CardContent>{children}</CardContent>
  </Card>
);

const HomePage = (props) => {
  const classes = useStyles(props);
  return (
    <div className={classes.container}>
      <HomeCard className={classes.card}>
        <Filters
          classes={classes}
          user={props.user}
          month={props.month}
          changeMonth={props.changeMonth}
          clients={props.clients}
          client={props.client}
          selectClient={props.selectClient}
          processing={props.processing}
          refresh={props.refresh}
          autorefresh={props.autorefresh}
          toggleAutoRefresh={props.toggleAutoRefresh}
        />
      </HomeCard>

      {props.client !== null && (
        <HomeCard className={classes.card}>
          <StatusChart chargeboxes={props.chargeboxes} status={props.status} running={props.running} />
        </HomeCard>
      )}

      {!props.processing && props.client !== null && Object.keys(props.charges).length > 0 && (
        <HomeCard title={<Translate id='home.charges.title' />} className={classes.card}>
          <ChargesChart charges={props.charges} month={props.month} locale={props.user.locale} />
        </HomeCard>
      )}

      {!props.processing && props.client !== null && props.energy.length > 0 && (
        <HomeCard title={<Translate id='home.energy.title' />} subtitle={<Translate id='home.energy.subtitle' />} className={classes.card}>
          <EnergyChart energy={props.energy} locale={props.user.locale} />
        </HomeCard>
      )}
      {!props.processing && props.client !== null && props.revenue.length > 0 && (
        <HomeCard title={<Translate id='home.revenue.title' />} subtitle={<Translate id='home.revenue.subtitle' />} className={classes.card}>
          <RevenueChart revenue={props.revenue} locale={props.user.locale} />
        </HomeCard>
      )}

      {!props.processing && props.client !== null && Object.keys(props.mostUsedChargeboxes).length > 0 && (
        <HomeCard title={<Translate id='home.mostUsed.title' />} subtitle={<Translate id='home.mostUsed.subtitle' />} className={classes.card}>
          <MostUsedChargeboxes mostUsedChargeboxes={props.mostUsedChargeboxes} chargeboxes={props.chargeboxes} />
        </HomeCard>
      )}
      {!props.processing && props.client !== null && Object.keys(props.mostActiveUsers).length > 0 && (
        <HomeCard title={<Translate id='home.mostActive.title' />} subtitle={<Translate id='home.mostActive.subtitle' />} className={classes.card}>
          <MostActiveUsers mostActiveUsers={props.mostActiveUsers} chargeboxes={props.chargeboxes} />
        </HomeCard>
      )}

      {!props.processing && props.client !== null && (props.energy.length > 0 || props.revenue.length > 0) && (
        <HomeCard className={classes.card}>
          {console.log(props)}
          <Total energy={props.energy} revenue={props.revenue} currency={props.client.currency} />
        </HomeCard>
      )}
      {!props.processing && props.client !== null && Object.keys(props.paymentMethods).length > 0 && (
        <HomeCard title={<Translate id='home.paymentMethods.title' />} subtitle={<Translate id='home.paymentMethods.subtitle' />} className={classes.card}>
          <PaymentMethodsChart paymentMethods={props.paymentMethods} />
        </HomeCard>
      )}
      {!props.processing && props.client !== null && (props.api.callsIn || props.api.callsOut) && (
        <HomeCard title={<Translate id='home.api.title' />} subtitle={<Translate id='home.api.subtitle' />} className={classes.card}>
          <ApiCalls callsIn={props.api.callsIn || 0} callsOut={props.api.callsOut || 0} />
        </HomeCard>
      )}
    </div>
  );
};

export default HomePage;
